import React from "react"
import PageBase from "../../components/pageBase";

const CareersPage = () => {
    return (
        <PageBase>
            <section className="wrapper bg-light">
                <div className="py-14 pt-md-17 container">
                    <div className="row">
                        <div className="mx-auto text-center col-lg-9 col-md-10 offset-lg-2 offset-md-1">
                            <h2 className="fs-15 text-uppercase text-muted mb-3">About</h2>
                            <h3 className="display-4 mb-10 px-xl-10 px-xxl-15">Careers</h3>
                            <p>Migrations.ml is an early phase start-up with a focus on delivering value to the wholesale fixed income market. 
                                We're a small company located in Toronto's financial district and are committed to providing an environment in 
                                which talented people can take the next step of their careers.</p>
                            <div className="row">
                                <div className="col-xl-10 mx-auto">
                                    <div className="card image-wrapper bg-full bg-image bg-overlay bg-overlay-400 bg-soft-primary mt-10">
                                        <div className="card-body p-6 p-md-11 d-lg-flex flex-row align-items-lg-center justify-content-md-between text-center text-lg-start">
                                            <h3 className="display-6 mb-6 mb-lg-0 pe-lg-10 pe-xl-5 pe-xxl-18 text-white">If you're passionate about fixed income and machine learning, we'd love to hear from you.</h3>
                                            <a href="mailto:info@migrations.ml?subject=Career with Migrations" className="btn btn-white rounded-pill mb-0 text-nowrap">Get In Touch</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </PageBase>
      );
}

export default CareersPage;